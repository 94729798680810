import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import Drawer from '@mui/material/Drawer';
import NoSsr from '@mui/material/NoSsr';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageSelector from './language-selector';

import * as styles from './header.module.css';

const NavEnglish = ({locale}) => (
  <nav className={styles.nav}>
    <Link to="/en" className={styles.brandLogo}>
      Lotsstugan
    </Link>
    <Link to="/en#about">About</Link>
    <Link to="/en#conference">Conference</Link>
    <Link to="/en/bed-and-breakfast">B&B</Link>
    <Link to="/en/glamping">Glamping</Link>
    <LanguageSelector locale={locale} />
  </nav>
);

const NavSwedish = ({locale}) => (
  <nav className={styles.nav}>
    <Link to="/" className={styles.brandLogo}>
      Lotsstugan
    </Link>
    <Link to="/#om-oss">Om oss</Link>
    <Link to="/#konferens">Konferens</Link>
    <Link to="/bed-and-breakfast">B&B</Link>
    <Link to="/glamping">Glamping</Link>
    <LanguageSelector locale={locale} />
  </nav>
);

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerActive: false,
      width: (typeof window !== 'undefined' && window.innerWidth) || null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleDrawer = () => {
    this.setState({drawerActive: !this.state.drawerActive});
  };

  handleResize = () => {
    this.setState({width: window.innerWidth});
  };

  renderNavigation() {
    return this.props.locale === 'sv-SE' ? (
      <NavSwedish locale={this.props.locale} />
    ) : (
      <NavEnglish locale={this.props.locale} />
    );
  }

  renderMobile() {
    return <>
      <IconButton aria-label="Open menu" onClick={this.toggleDrawer} size="large">
        <MenuIcon />
      </IconButton>
      <Drawer
        className={styles.drawer}
        open={this.state.drawerActive}
        onClose={this.toggleDrawer}>
        {this.renderNavigation()}
      </Drawer>
    </>;
  }

  renderDesktop() {
    return this.renderNavigation();
  }

  render() {
    const {width} = this.state;
    const isDesktop = width && width >= 1000;

    return (
      <header className={styles.header}>
        <NoSsr>{isDesktop ? this.renderDesktop() : this.renderMobile()}</NoSsr>
      </header>
    );
  }
}

Header.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Header;
