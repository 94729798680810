import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const handleChange = e => {
  switch (e.target.value) {
    case 'sv-SE':
      navigate('/');
      break;
    case 'en':
      navigate('/en/');
      break;
    default:
      break;
  }
};

const LanguageSelector = ({locale}) => (
  <Select
    disableUnderline
    onChange={handleChange}
    value={locale}
  >
    <MenuItem value="sv-SE">Svenska</MenuItem>
    <MenuItem value="en">English</MenuItem>
  </Select>
);

LanguageSelector.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default LanguageSelector;
