import React from 'react';
import {useCookies} from 'react-cookie';
import Button from '@mui/material/Button';
import * as styles from './cookie-consent.module.css';

const text = {
  'sv-SE': {
    info:
      'Lotsstugan använder kakor för att följa upp och utvärdera användningen' +
      ' av vår webbplats. Genom att använda vår webbplats accepterar du att kakor' +
      ' används. Du kan styra användningen av kakor i inställningarna i din' +
      ' webbläsare.',
    close: 'Stäng',
  },
  en: {
    info:
      'Lotsstugan use cookies to analyze and evaluate the usage of our ' +
      'web site. By using our web site you accept that cookies are being used.' +
      ' You can change cookie preferences in your web browser settings.',
    close: 'Close',
  },
};

const CookieConsent = ({locale = 'sv-SE'}) => {
  const [cookies, setCookie] = useCookies(['hideCookieConsent']);

  const handleCloseClick = e => {
    e.preventDefault();
    setCookie('hideCookieConsent', '1', {path: '/'});
  };

  if (
    typeof window === 'undefined' ||
    typeof cookies.hideCookieConsent !== 'undefined'
  ) {
    return null;
  }

  return (
    <div className={styles.cookieConsent}>
      <span>{text[locale].info}</span>
      <Button
        color="primary"
        className={styles.closeButton}
        onClick={handleCloseClick}>
        {text[locale].close}
      </Button>
    </div>
  );
};

export default CookieConsent;
