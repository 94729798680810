import React from 'react';

import * as styles from './footer.module.css';

const Footer = ({contactInfo, locale}) => (
  <footer className={styles.footer}>
    <span>{contactInfo.address}</span>
    <span>
      <a href={`tel:${contactInfo.phoneNumber}`}>
        Tel: {contactInfo.phoneNumber}
      </a>
    </span>
    <span>
      <a href={`mailto:${contactInfo.email}`}>
        {locale === 'sv-SE' ? 'E-post' : 'Email'}: {contactInfo.email}
      </a>
    </span>
    <span>© 2023 Lotsstugan</span>
  </footer>
);

export default Footer;
