import React from "react";
import RootProviders from "./src/components/root-providers";
import Layout from "./src/components/layout";

export const wrapPageElement = ({ element, props }) => {
  return (
    <RootProviders>
      <Layout {...props}>{element}</Layout>
    </RootProviders>
  );
};

export const onClientEntry = () => {
  require("whatwg-fetch");
  require("core-js/features/array/from");
  require("core-js/features/array/find");
  require("core-js/features/array/includes");
};
