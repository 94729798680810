import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import Header from '../header';
import Footer from '../footer';
import CookieConsent from '../cookie-consent';
import * as styles from './base.module.css';

import './index.css';

const getLocaleFromPath = path =>
  path.indexOf('/en/') === 0 || path.indexOf('/en#') === 0 || path === '/en'
    ? 'en'
    : 'sv-SE';

const query = graphql`
  query IndexLayoutQuery {
    site {
      siteMetadata {
        title
        description
      }
    }

    contentfulContactInfo {
      address
      phoneNumber
      email
    }
  }
`;

const Layout = ({location, children}) => {
  const locale = getLocaleFromPath(location.pathname);

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={styles.root}>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: data.site.siteMetadata.description,
              },
              {
                name: 'viewport',
                content:
                  'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no',
              },
            ]}>
            <link
              href="https://fonts.googleapis.com/css?family=Roboto|Prata"
              rel="stylesheet"
            />
            <link
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
              rel=""
            />
          </Helmet>
          <Header locale={locale} />
          {typeof children === 'function' ? children() : children}
          <Footer locale={locale} contactInfo={data.contentfulContactInfo} />
          <CookieConsent locale={locale} />
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default Layout;
