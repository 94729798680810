import {blueGrey, pink, red, common} from '@mui/material/colors';
import { createTheme, adaptV4Theme } from '@mui/material/styles';

const headerFontFamily = [
  'Prata',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  '"Fira Sans"',
  '"Droid Sans"',
  '"Helvetica Neue"',
  'sans-serif'
];

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: blueGrey[500],
      dark: blueGrey[700],
      contrast: common.white,
    },
    secondary: {
      main: pink.A200,
      dark: pink[700],
      contrast: common.white,
    },
    error: red,
    background: {
      main: common.white,
    },
  },
  typography: {
    h1: {
      fontFamily: headerFontFamily
    },
    h2: {
      fontFamily: headerFontFamily
    },
    h3: {
      fontFamily: headerFontFamily
    },
    h4: {
      fontFamily: headerFontFamily
    },
    h5: {
      fontFamily: headerFontFamily
    },
    h6: {
      fontFamily: headerFontFamily
    }
  }

}));

export default theme;
