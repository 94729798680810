import React from 'react';
import PropTypes from 'prop-types';
import {CookiesProvider} from 'react-cookie';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from '../../src/theme';

const RootProviders = ({children}) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
            {typeof children === 'function' ? children() : children}
        </CookiesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

RootProviders.propTypes = {
  children: PropTypes.object,
};

export default RootProviders;
